export default {
  methods: {
    /**
     * Open mobile aside menu by imitating click on kt_aside_mobile_toggle
     * @return {void}
     */
    openMobileAside() {
      document.querySelector("#kt_aside_mobile_toggle").click();
    },

    /**
     * Close mobile aside menu by imitating click on kt_aside_close_btn
     * @return {void}
     */
    closeMobileAside() {
      document.querySelector("#kt_aside_close_btn").click();
    }
  }
};
